<template>
  <s-dialog to="add-product-mass-dialog" size="lg" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="!h-[76.4rem]">
      <st-dialog-header @clickClose="closeDialog">
        <span class="font-bold text-wrap">{{
          `${projectName} ${$t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.reg_pg_title')}`
        }}</span>
      </st-dialog-header>
      <s-dialog-content class="flex-1 !pr-8">
        <s-dialog-content-body class="flex flex-col h-full">
          <p class="shrink-0 pr-20 text-sm leading-md text-on-surface-elevation-3">
            {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.reg_pg_guide1') }}<br />
            {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.reg_pg_guide2') }}
          </p>

          <div class="studio-scrollbar-4 flex-1 mt-12">
            <div class="flex flex-col gap-12">
              <div class="flex items-center gap-16 w-full">
                <p class="flex-1 text-md font-bold leading-lg text-on-surface-elevation-2">
                  {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.reg_pg_prod_name') }}
                </p>
                <p class="shrink-0 w-260 text-md font-bold leading-lg text-on-surface-elevation-2">
                  {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.reg_pg_prod_type') }}
                </p>
              </div>
              <div v-for="(__, index) in products" :key="index" class="flex gap-16 w-full">
                <div class="flex-1">
                  <input-text
                    :placeholder="
                      $t(
                        'studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.reg_pg_prod_name_place_holder'
                      )
                    "
                    maxLength="50"
                    :name="`products[${index}].productName`"
                    :rules="{
                      required: {
                        value: true,
                        message: $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_name_val_msg1')
                      },
                      max_length: 50,
                      check_duplicate: {
                        value: true,
                        message: $t('studio.prj_prod_mngmt.prj_reg.prod_name_val_msg3')
                      },
                      regex: {
                        regex: REGEX_PRODUCT_NAME,
                        message: $t(
                          'studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid'
                        )
                      }
                    }"
                  />
                </div>
                <div class="relative shrink-0 w-260">
                  <dropdown
                    :name="`products[${index}].productType`"
                    :options="items"
                    :dropdownProps="{
                      size: 'lg',
                      variant: 'line',
                      distance: 4,
                      offset: [0, 0],
                      placement: 'bottom',
                      class: 'w-full'
                    }"
                    closeOnClick
                    dropdownBtnClass="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button class="sm:min-w-264" @click="onSubmit">
          {{ $t('studio.common.popup_case_save_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="add-product-mass-dialog" />
</template>
<script setup lang="ts">
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldValue } from 'vee-validate';
import { ref } from 'vue';

import { checkProductListInProjectNameApi } from '@/apis/project-product.api';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { PRODUCT_TYPES } from '@/constants/project-product.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { RuleNames } from '@/enums/validation.enum';
import { useUserStore } from '@/stores/user.store';
import type { FormOption } from '@/types/common/form.type';
import type {
  ProductModel,
  ProductProjectRequest
} from '@/types/project-product/project-product-model.type';
import { generateErrorMsg } from '@/utils/validation.util';

interface AddProductMassForm {
  products: ProductModel[];
}

const props = defineProps<{
  numberOfProducts: number;
  productOriginal: ProductModel;
  groupId: string;
  projectName: string;
}>();

const emit = defineEmits<{
  close: [products: ProductModel[]];
}>();

const { handleSubmit, setErrors } = useForm<AddProductMassForm>({
  initialValues: {
    products: []
  }
});

const items: FormOption[] = PRODUCT_TYPES;
const products = ref<ProductProjectRequest[]>(
  Array.from(Array(Number(props.numberOfProducts)), (_: string, index: number) => ({
    productName: `${props.productOriginal.productName}${index ? ' ' + index : ''}`,
    productType: props.productOriginal.productType
  }))
);

const setProducts = useSetFieldValue('products');
const registerProducts = useFieldValue<ProductProjectRequest[]>('products');

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const closeDialog = () => {
  emit('close', []);
};

const checkDuplicates = (arr: string[]) => {
  const counts = _.countBy(arr);
  const result = arr.map((value: string) => counts[value] > 1);
  return result;
};

const onSubmit = handleSubmit(async (value: AddProductMassForm) => {
  const duplicateArrays = checkDuplicates(
    registerProducts.value.map((product: ProductProjectRequest) => product.productName.trim())
  );

  if (duplicateArrays.includes(true)) {
    registerProducts.value.forEach((_: ProductProjectRequest, index: number) => {
      if (duplicateArrays[index]) {
        setErrors({
          [`products[${index}].productName`]: generateErrorMsg(
            'studio.prj_prod_mngmt.prj_reg.prod_name_val_msg3',
            RuleNames.CHECK_DUPLICATE
          )
        });
      }
    });

    return;
  }

  const checkProdNameList = await checkProductListInProjectNameApi({
    groupId: props.groupId,
    productNames: [
      ...(registerProducts.value || []).map((product: ProductProjectRequest) => product.productName)
    ],
    languageCd: selectedGroupInfo.value?.languageCd || 'ko'
  });

  if (checkProdNameList?.duplicated?.length) {
    registerProducts.value.forEach((product: ProductProjectRequest, index: number) => {
      if (checkProdNameList.duplicated.includes(product.productName.trim())) {
        setErrors({
          [`products[${index}].productName`]: generateErrorMsg(
            'studio.prj_prod_mngmt.prj_reg.prod_name_val_msg3',
            RuleNames.CHECK_DUPLICATE
          )
        });
      }
    });

    return;
  }

  emit('close', value.products);
});

const triggerValidateProductNames = () => {
  products.value.forEach((product: ProductProjectRequest, index: number) => {
    if (product.productName.length > 60) {
      setErrors({
        [`products[${index}].productName`]: generateErrorMsg(
          'studio.common.def_key.exceed',
          RuleNames.MAX_LENGTH
        )
      });
    }
  });
};

const init = async () => {
  setProducts(products.value);
  triggerValidateProductNames();
};

init();
</script>
